import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { RadioGroup, Radio, Button, Toast  } from "vant"
import './assets/css/index.css'
/**-----------------设置适配信息-----------------------**/
import 'lib-flexible'

/**-----------------路由守卫-----------------------**/
router.beforeEach((to,from,next)=>{
  window.document.title = to.meta.title
  next()
})

let app = createApp(App)
app.use(Radio);
app.use(RadioGroup);
app.use(Button);
app.use(Toast);

app.use(store)
app.use(router)
app.mount("#app")