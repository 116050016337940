import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/investigation'
  },
  {
    path: '/investigation', // 报告解读首页
    name: '满意度调查',
    component: () => import('../views/Investigation/Investigation.vue'),
    meta: {
      title: '满意度调查', // 名字
      requireAuth: true, // 判断是否授权
      ifIncludes: false, // 判断是否缓存
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
